var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('page-title'),_c('b-card',{staticClass:"mt-1"},[_c('b-table',{ref:"refProjectListTable",staticClass:"position-relative",attrs:{"empty-text":_vm.$t('No Data'),"fields":_vm.$t('tableColumnsProject'),"items":_vm.data,"primary-key":"id","responsive":"","show-empty":"","small":""},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(scope){return _vm._l((scope.fields),function(field){return _c('col',{key:field.key,class:field.key === 'description' ? 'ell' : '',style:({
						width: field.key === 'description' ? '40%' : '' || field.key === 'order' ? '6%' : '' || field.key === 'projectName' ? '20%' : '',
					})})})}},{key:"cell(order)",fn:function(data){return [_c('div',{staticClass:"text-center"},[_c('span',[_vm._v(_vm._s(data.index + 1))])])]}},{key:"cell(projectAvater)",fn:function(data){return [_c('b-media',{attrs:{"vertical-align":"center"},scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-img',{staticStyle:{"width":"100px"},attrs:{"src":data.item.images[0],"alt":""}})]},proxy:true}],null,true)})]}},{key:"cell(projectName)",fn:function(data){return [_c('span',{staticClass:"align-text-top text-capitalize"},[_vm._v(_vm._s(data.item.project_name))])]}},{key:"cell(description)",fn:function(data){return [_c('span',[_c('small',[_vm._v(_vm._s(data.item.description))])])]}},{key:"cell(location)",fn:function(data){return [_c('div',{staticClass:"text-nowrap"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_vm._v(" 经度： "),_c('span',[_vm._v(_vm._s(data.item.lng))])])],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_vm._v(" 纬度： "),_c('span',[_vm._v(_vm._s(data.item.lat))])])],1)],1)]}},{key:"cell(actions)",fn:function(data){return [_c('div',{staticClass:"align-middle text-center"},[_c('b-button',{staticClass:"btn-icon",attrs:{"to":{
							name: 'admin-projects-edit',
							params: {
								title: 'Project Edit',
								id: data.item.project_id,
								levels: [
									{ title: 'System Manage' },
									{
										title: 'Project List',
										name: 'admin-projects-list',
									} ],
							},
						},"size":"sm","variant":"warning"}},[_c('feather-icon',{attrs:{"icon":"EditIcon"}})],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
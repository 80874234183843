<template>
	<div>
		<page-title></page-title>
		<!-- Table Container Card -->
		<b-card class="mt-1">
			<b-table ref="refProjectListTable" :empty-text="$t('No Data')" :fields="$t('tableColumnsProject')" :items="data" class="position-relative" primary-key="id" responsive show-empty small>
				<template v-slot:table-colgroup="scope">
					<col
						v-for="field in scope.fields"
						:key="field.key"
						:class="field.key === 'description' ? 'ell' : ''"
						:style="{
							width: field.key === 'description' ? '40%' : '' || field.key === 'order' ? '6%' : '' || field.key === 'projectName' ? '20%' : '',
						}"
					/>
				</template>
				<!-- Column: Project-Avater -->
				<template #cell(order)="data">
					<div class="text-center">
						<span>{{ data.index + 1 }}</span>
					</div>
				</template>
				<template #cell(projectAvater)="data">
					<b-media vertical-align="center">
						<template #aside>
							<b-img :src="data.item.images[0]" alt="" style="width: 100px" />
						</template>
					</b-media>
				</template>

				<!-- Column:project_name -->
				<template #cell(projectName)="data">
					<span class="align-text-top text-capitalize">{{ data.item.project_name }}</span>
				</template>

				<!-- Column: description -->
				<template #cell(description)="data">
					<span
						><small>{{ data.item.description }}</small></span
					>
				</template>
				<!-- location -->
				<template #cell(location)="data">
					<div class="text-nowrap">
						<b-row>
							<b-col cols="12" md="12">
								经度：
								<span>{{ data.item.lng }}</span>
							</b-col>
						</b-row>
						<b-row>
							<b-col cols="12" md="12">
								纬度：
								<span>{{ data.item.lat }}</span>
							</b-col>
						</b-row>
					</div>
				</template>

				<!-- Column: Actions -->
				<template #cell(actions)="data">
					<div class="align-middle text-center">
						<b-button
							:to="{
								name: 'admin-projects-edit',
								params: {
									title: 'Project Edit',
									id: data.item.project_id,
									levels: [
										{ title: 'System Manage' },
										{
											title: 'Project List',
											name: 'admin-projects-list',
										},
									],
								},
							}"
							class="btn-icon"
							size="sm"
							variant="warning"
						>
							<feather-icon icon="EditIcon" />
						</b-button>
					</div>
				</template>
			</b-table>
		</b-card>
	</div>
</template>

<script>
	import vSelect from "vue-select";
	import store from "@/store";
	import { getCurrentInstance, ref } from "@vue/composition-api";
	import { avatarText } from "@/@core/utils/filter";
	import useProjectsList from "./useProjectsList";
	import PageTitle from "@/views/layout/PageTitle";

	export default {
		components: {
			PageTitle,
			vSelect,
		},
		data() {
			return {
				data: [],
			};
		},
		setup() {
			let instance = getCurrentInstance().proxy;

			const searchProject = (value) => {
				store
					.dispatch("projects/fetchProjects", {
						project_name: value,
						pagesize: 10000,
					})
					.then((response) => {
						console.log(response.data);
						instance.data = response.data;
					})
					.catch((error) => {
						console.log(error);
					});
			};
			const isAddNewProjectSidebarActive = ref(false);
			const { refProjectListTable, refetchData } = useProjectsList();

			return {
				// Sidebar
				isAddNewProjectSidebarActive,
				refProjectListTable,
				refetchData,

				// Filter
				avatarText,
			};
		},
	};
</script>

<style lang="scss" scoped>
	.per-page-selector {
		width: 90px;
	}
</style>

<style lang="scss">
	//noinspection CssUnknownTarget
	@import "@core/scss/vue/libs/vue-select.scss";

	.table th:nth-last-child(1) {
		text-align: center;
	}

	.table {
		table-layout: fixed;
	}

	.table td:nth-child(4) {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		// display: -webkit-box;
		//  text-overflow:ellipsis;
		// -webkit-line-clamp: 3;
		// -webkit-box-orient: vertical;
		// overflow: hidden;
	}
</style>
